<!-- 移动端下单价格配置弹窗 -->
<template>
  <div>
    <div style="display: flex;justify-content: space-between;">
      <div>
        <span style="font-size: 24px;font-weight: 500;color: #707070;" v-if="isUpdate === false">添加价格配置</span>
        <span style="font-size: 24px;font-weight: 500;color: #707070;" v-else>修改价格配置</span>
      </div>
      <div>
        <el-button style="background: #E4E5E9!important;border-color: #E4E5E9!important;color: #3F4157!important;" @click="returnPage()">返回</el-button>
      </div>
    </div>

    <el-card shadow="never" style="margin-top: 20px;">
      <el-form
        ref="form"
        :model="form"
        label-width="100px">

        <el-row :gutter="15">
          <el-col :span="6">
            <el-form-item label="名称" label-width="50px">
              <el-input clearable v-model="form.name" placeholder="请输入名称"/>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="区域">
              <el-cascader
                  ref="refCascader"
                  :options="city"
                  v-model="form.district"
                  @change="handleChange"></el-cascader>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="">
              <div style="display: flex;align-items: center;">
                <span style="margin-right: 10px;">已启用</span>
                <el-switch v-model="form.status" :active-value="0" :inactive-value="1"></el-switch>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="15">
          <el-col :span="24">
            <el-form-item label="描述" label-width="50px">
              <el-input
                clearable
                type="textarea"
                :autosize="{ minRows: 3, maxRows: 5}"
                placeholder="请输入描述"
                v-model="form.description">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="15" style="padding-left: 20px;padding-right: 20px;" v-for="(item,index) in List" :key="index">
          <div>
            <div style="display: flex;align-items: center;">
              <span style="width: 5px;height: 19px;background: #ff9b05;display: inline-block;margin-right: 10px;"></span>
              <!--<span style="font-size: 20px;font-weight: 700;color: #050505;">救援项目</span>-->
              <span style="margin-left: 20px;">
                 <el-checkbox v-model="item.switch">
                   <span style="font-size: 15px;font-weight: 700;color: #050505;">{{item.name}}</span>
                 </el-checkbox>
              </span>
            </div>
          </div>
          <el-row :gutter="15" style="margin-top: 20px;">
            <el-col :span="6" v-for="(it,idx) in item.data" :key="idx">
              <el-form-item :label='it.label'>
                <el-input placeholder="请输入" v-model="it.value" clearable>
                  <template slot="append">{{it.unit}}</template>
                </el-input>
              </el-form-item>
            </el-col>
          <!--  <el-col :span="6">-->
          <!--    <el-form-item label="起步公里">-->
          <!--      <el-input placeholder="" v-model="form.input2" clearable>-->
          <!--        <template slot="append">公里</template>-->
          <!--      </el-input>-->
          <!--    </el-form-item>-->
          <!--  </el-col>-->
          <!--  <el-col :span="6">-->
          <!--    <el-form-item label="超出">-->
          <!--      <el-input placeholder="" v-model="form.input2" clearable>-->
          <!--        <template slot="append">元/公里</template>-->
          <!--      </el-input>-->
          <!--    </el-form-item>-->
          <!--  </el-col>-->
          <!--  <el-col :span="6">-->
          <!--    <el-form-item label="一拖二">-->
          <!--      <el-input placeholder="" v-model="form.input2" clearable>-->
          <!--        <template slot="append">元/个</template>-->
          <!--      </el-input>-->
          <!--    </el-form-item>-->
          <!--  </el-col>-->
          <!--</el-row>-->
          <!--<el-row :gutter="15">-->
          <!--  <el-col :span="6">-->
          <!--    <el-form-item label="5吨板">-->
          <!--      <el-input placeholder="" v-model="form.input2" clearable>-->
          <!--        <template slot="append">元/次</template>-->
          <!--      </el-input>-->
          <!--    </el-form-item>-->
          <!--  </el-col>-->
          <!--  <el-col :span="6">-->
          <!--    <el-form-item label="全落地">-->
          <!--      <el-input placeholder="" v-model="form.input2" clearable>-->
          <!--        <template slot="append">元/次</template>-->
          <!--      </el-input>-->
          <!--    </el-form-item>-->
          <!--  </el-col>-->
          <!--  <el-col :span="6">-->
          <!--    <el-form-item label="空驶金额">-->
          <!--      <el-input placeholder="" v-model="form.input2" clearable>-->
          <!--        <template slot="append">元</template>-->
          <!--      </el-input>-->
          <!--    </el-form-item>-->
          <!--  </el-col>-->
          <!--</el-row>-->
          <!--<el-row :gutter="15">-->
          <!--  <el-col :span="24">-->
          <!--    <el-form-item label="服务说明">-->
          <!--      <el-input-->
          <!--        clearable-->
          <!--        type="textarea"-->
          <!--        :autosize="{ minRows: 3, maxRows: 5}"-->
          <!--        placeholder="请输入"-->
          <!--        v-model="form.textarea2">-->
          <!--      </el-input>-->
          <!--    </el-form-item>-->
          <!--  </el-col>-->
          </el-row>
        </el-row>


        <!--<el-row :gutter="15" style="padding-left: 20px;padding-right: 20px;">-->
        <!--  <div>-->
        <!--    <div style="display: flex;align-items: center;">-->
        <!--      <span style="width: 5px;height: 19px;background: #ff9b05;display: inline-block;margin-right: 10px;"></span>-->
        <!--      <span style="font-size: 20px;font-weight: 700;color: #050505;">救援类型</span>-->
        <!--      <span style="margin-left: 20px;">-->
        <!--         <el-checkbox v-model="form.checked">拖车</el-checkbox>-->
        <!--      </span>-->
        <!--    </div>-->
        <!--  </div>-->
        <!--  <el-row :gutter="15" style="margin-top: 20px;">-->
        <!--    <el-col :span="6">-->
        <!--      <el-form-item label="起步价">-->
        <!--        <el-input placeholder="" v-model="form.input2" clearable>-->
        <!--          <template slot="append">元</template>-->
        <!--        </el-input>-->
        <!--      </el-form-item>-->
        <!--    </el-col>-->
        <!--    <el-col :span="6">-->
        <!--      <el-form-item label="起步公里">-->
        <!--        <el-input placeholder="" v-model="form.input2" clearable>-->
        <!--          <template slot="append">公里</template>-->
        <!--        </el-input>-->
        <!--      </el-form-item>-->
        <!--    </el-col>-->
        <!--    <el-col :span="6">-->
        <!--      <el-form-item label="超出">-->
        <!--        <el-input placeholder="" v-model="form.input2" clearable>-->
        <!--          <template slot="append">元/公里</template>-->
        <!--        </el-input>-->
        <!--      </el-form-item>-->
        <!--    </el-col>-->
        <!--    <el-col :span="6">-->
        <!--      <el-form-item label="一拖二">-->
        <!--        <el-input placeholder="" v-model="form.input2" clearable>-->
        <!--          <template slot="append">元/个</template>-->
        <!--        </el-input>-->
        <!--      </el-form-item>-->
        <!--    </el-col>-->
        <!--  </el-row>-->
        <!--  <el-row :gutter="15">-->
        <!--    <el-col :span="6">-->
        <!--      <el-form-item label="辅轮">-->
        <!--        <el-input placeholder="" v-model="form.input2" clearable>-->
        <!--          <template slot="append">元/个</template>-->
        <!--        </el-input>-->
        <!--      </el-form-item>-->
        <!--    </el-col>-->
        <!--    <el-col :span="6">-->
        <!--      <el-form-item label="地库">-->
        <!--        <el-input placeholder="" v-model="form.input2" clearable>-->
        <!--          <template slot="append">元/次</template>-->
        <!--        </el-input>-->
        <!--      </el-form-item>-->
        <!--    </el-col>-->
        <!--    <el-col :span="6">-->
        <!--      <el-form-item label="5吨板">-->
        <!--        <el-input placeholder="" v-model="form.input2" clearable>-->
        <!--          <template slot="append">元</template>-->
        <!--        </el-input>-->
        <!--      </el-form-item>-->
        <!--    </el-col>-->
        <!--  </el-row>-->
        <!--  <el-row :gutter="15">-->
        <!--    <el-col :span="24">-->
        <!--      <el-form-item label="服务说明">-->
        <!--        <el-input-->
        <!--          clearable-->
        <!--          type="textarea"-->
        <!--          :autosize="{ minRows: 3, maxRows: 5}"-->
        <!--          placeholder="请输入"-->
        <!--          v-model="form.textarea2">-->
        <!--        </el-input>-->
        <!--      </el-form-item>-->
        <!--    </el-col>-->
        <!--  </el-row>-->
        <!--</el-row>-->


        <!--<el-row :gutter="15" style="padding-left: 20px;padding-right: 20px;">-->
        <!--  <div>-->
        <!--    <div style="display: flex;align-items: center;">-->
        <!--      <span style="width: 5px;height: 19px;background: #ff9b05;display: inline-block;margin-right: 10px;"></span>-->
        <!--      <span style="font-size: 20px;font-weight: 700;color: #050505;">救援类型</span>-->
        <!--      <span style="margin-left: 20px;">-->
        <!--         <el-checkbox v-model="form.checked">塔电</el-checkbox>-->
        <!--      </span>-->
        <!--    </div>-->
        <!--  </div>-->
        <!--  <el-row :gutter="15" style="margin-top: 20px;">-->
        <!--    <el-col :span="6">-->
        <!--      <el-form-item label="一口价">-->
        <!--        <el-input placeholder="" v-model="form.input2" clearable>-->
        <!--          <template slot="append">元</template>-->
        <!--        </el-input>-->
        <!--      </el-form-item>-->
        <!--    </el-col>-->
        <!--    <el-col :span="6">-->
        <!--      <el-form-item label="空驶金额">-->
        <!--        <el-input placeholder="" v-model="form.input2" clearable>-->
        <!--          <template slot="append">公里</template>-->
        <!--        </el-input>-->
        <!--      </el-form-item>-->
        <!--    </el-col>-->
        <!--  </el-row>-->
        <!--  <el-row :gutter="15">-->
        <!--    <el-col :span="24">-->
        <!--      <el-form-item label="服务说明">-->
        <!--        <el-input-->
        <!--          clearable-->
        <!--          type="textarea"-->
        <!--          :autosize="{ minRows: 3, maxRows: 5}"-->
        <!--          placeholder="请输入"-->
        <!--          v-model="form.textarea2">-->
        <!--        </el-input>-->
        <!--      </el-form-item>-->
        <!--    </el-col>-->
        <!--  </el-row>-->
        <!--</el-row>-->


        <!--<el-row :gutter="15" style="padding-left: 20px;padding-right: 20px;">-->
        <!--  <div>-->
        <!--    <div style="display: flex;align-items: center;">-->
        <!--      <span style="width: 5px;height: 19px;background: #ff9b05;display: inline-block;margin-right: 10px;"></span>-->
        <!--      <span style="font-size: 20px;font-weight: 700;color: #050505;">救援类型</span>-->
        <!--      <span style="margin-left: 20px;">-->
        <!--         <el-checkbox v-model="form.checked">换胎</el-checkbox>-->
        <!--      </span>-->
        <!--    </div>-->
        <!--  </div>-->
        <!--  <el-row :gutter="15" style="margin-top: 20px;">-->
        <!--    <el-col :span="6">-->
        <!--      <el-form-item label="一口价">-->
        <!--        <el-input placeholder="" v-model="form.input2" clearable>-->
        <!--          <template slot="append">元</template>-->
        <!--        </el-input>-->
        <!--      </el-form-item>-->
        <!--    </el-col>-->
        <!--    <el-col :span="6">-->
        <!--      <el-form-item label="空驶金额">-->
        <!--        <el-input placeholder="" v-model="form.input2" clearable>-->
        <!--          <template slot="append">公里</template>-->
        <!--        </el-input>-->
        <!--      </el-form-item>-->
        <!--    </el-col>-->
        <!--  </el-row>-->
        <!--  <el-row :gutter="15">-->
        <!--    <el-col :span="24">-->
        <!--      <el-form-item label="服务说明">-->
        <!--        <el-input-->
        <!--          clearable-->
        <!--          type="textarea"-->
        <!--          :autosize="{ minRows: 3, maxRows: 5}"-->
        <!--          placeholder="请输入"-->
        <!--          v-model="form.textarea2">-->
        <!--        </el-input>-->
        <!--      </el-form-item>-->
        <!--    </el-col>-->
        <!--  </el-row>-->
        <!--</el-row>-->

        <!--<el-row :gutter="15" style="padding-left: 20px;padding-right: 20px;">-->
        <!--  <div>-->
        <!--    <div style="display: flex;align-items: center;">-->
        <!--      <span style="width: 5px;height: 19px;background: #ff9b05;display: inline-block;margin-right: 10px;"></span>-->
        <!--      <span style="font-size: 20px;font-weight: 700;color: #050505;">救援类型</span>-->
        <!--      <span style="margin-left: 20px;">-->
        <!--         <el-checkbox v-model="form.checked">困境</el-checkbox>-->
        <!--      </span>-->
        <!--    </div>-->
        <!--  </div>-->
        <!--  <el-row :gutter="15" style="margin-top: 20px;">-->
        <!--    <el-col :span="6">-->
        <!--      <el-form-item label="一口价">-->
        <!--        <el-input placeholder="" v-model="form.input2" clearable>-->
        <!--          <template slot="append">元</template>-->
        <!--        </el-input>-->
        <!--      </el-form-item>-->
        <!--    </el-col>-->
        <!--    <el-col :span="6">-->
        <!--      <el-form-item label="空驶金额">-->
        <!--        <el-input placeholder="" v-model="form.input2" clearable>-->
        <!--          <template slot="append">公里</template>-->
        <!--        </el-input>-->
        <!--      </el-form-item>-->
        <!--    </el-col>-->
        <!--  </el-row>-->
        <!--  <el-row :gutter="15">-->
        <!--    <el-col :span="24">-->
        <!--      <el-form-item label="服务说明">-->
        <!--        <el-input-->
        <!--          clearable-->
        <!--          type="textarea"-->
        <!--          :autosize="{ minRows: 3, maxRows: 5}"-->
        <!--          placeholder="请输入"-->
        <!--          v-model="form.textarea2">-->
        <!--        </el-input>-->
        <!--      </el-form-item>-->
        <!--    </el-col>-->
        <!--  </el-row>-->
        <!--</el-row>-->
      </el-form>
    </el-card>

    <div style="text-align: center;margin-top: 30px;">
      <el-button style="background: #FEA837!important;border-color: #FEA837!important;color: #FFFFFF!important;width: 240px!important;" @click="save">提交</el-button>
    </div>

  </div>
</template>

<script>
//引入的接口
import {
  getcityList,
} from '@/api/yunli'
import {Detailsmobile_price, Editmobile_price, getmobileterminal, Savemobile_price} from "@/api/operate";

export default {
  props: {
    // 修改回显的数据
    data: Object
  },
  components: { },
  data() {
    return {
      // 表单数据
      form: {},
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false,

      //城市列表
      city:[],

      List:[],

      district:[],
      district1:[],

      xiugai:false,

    };
  },
  watch: {
    // data() {
    //   if (this.data) {
    //     console.log(this.data)
    //     this.form = Object.assign({}, this.data);
    //     this.isUpdate = true;
    //   } else {
    //     this.form = {};
    //     this.isUpdate = false;
    //   }
    // }
  },
  mounted() {
    if (this.data) {
      console.log(this.data)
      // this.form = Object.assign({}, this.data);
      this.form = {};
      this.isUpdate = true;
      //调用详情接口
      Detailsmobile_price(this.data.id).then(res => {
        console.log(res)
        // this.form = res.data;
        this.$set(this.form,'name',res.data.name)
        // this.form.name = res.data.name;
        // this.form.status = res.data.status;
        this.$set(this.form,'status',res.data.status)
        // this.form.description = res.data.description;
        this.$set(this.form,'description',res.data.description)
        this.form.district = res.data.codes;
        this.district1 = res.data.district;
        this.List = res.data.data;
        this.$forceUpdate();
      })

    } else {
      this.form = {};
      this.isUpdate = false;
      //调用获取配置
      this.getConfig();
    }

    //调用获取城市列表
    this.getCity();

  },
  methods: {
    // 获取配置
    getConfig(){
      getmobileterminal().then(res => {
        console.log(res);
        if(res.code == 200){
          this.List = res.data;
        }else {
          this.$message.error(res.msg)
        }
      })
    },

    // 获取城市列表
    getCity(){
      getcityList().then(res => {
        // console.log(res);
        this.city = res.data;
      })
    },

    /* 保存编辑 */
    save() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          if(this.isUpdate == false){
            this.form.district = this.district;
            let data = {
              name: this.form.name,
              district: this.form.district,
              description: this.form.description,
              status: this.form.status,
              data: this.List
            }
            Savemobile_price(data).then(res => {
              console.log(res)
              if(res.code == 200){
                this.$message.success(res.msg)
                this.loading = false;
                this.$emit('showEdit',false);
              }else {
                this.loading = false;
                this.$message.error(res.msg)
              }
            }).catch(e => {
              this.loading = false;
              this.$message.error(e.msg)
            })
          }else{
            if(this.xiugai == true){
              this.form.district = this.district
            }else {
              console.log('没有修改')
              this.form.district = this.district1
            }
            let data = {
              name: this.form.name,
              district: this.form.district,
              description: this.form.description,
              status: this.form.status,
              data: this.List
            }
            Editmobile_price(this.data.id,data).then(res => {
              if(res.code == 200){
                this.$message.success(res.msg)
                this.loading = false;
                this.$emit('showEdit',false);
              }else {
                this.loading = false;
                this.$message.error(res.msg)
              }
            })
          }
        } else {
          return false;
        }
      });
    },

    //点击返回按钮
    returnPage(){
      this.$emit('showEdit',false);
    },

    //选择区域
    //级联选择器
    handleChange(value) {
      const areaTextArr = [];
      const arr = this.$refs["refCascader"].getCheckedNodes()[0].pathNodes;
      arr.forEach((i) => {
        areaTextArr.push(i.label);
      });
      // console.log(areaTextArr);
      // console.log(value);
      let area = [];
      let province = value[0] + '|' + areaTextArr[0];
      let city = value[1] + '|' + areaTextArr[1];
      let district = value[2] + '|' + areaTextArr[2];
      area.push(province,city,district);
      console.log(area)
      this.district = area;
      this.xiugai = true;
    },


  },

}
</script>

<style scoped lang="scss">
.sijilist{
  .sijilistactive{
    cursor: pointer;
    display: inline-block;
    width: 64px;
    height: 36px;
    font-size: 16px;
    font-weight: 500;
    color: #868792;
    margin: 10px;
  }
  .sijilistactive1{
    color: #FF9B05;
    border-bottom: 4px solid #FF9B05;
  }
}

.sijiradio{

}
.listactive{
  width: 100%;
  border: 1px solid #ebecf2;
  border-radius: 6px;
  padding: 20px;
  margin-top: 15px;
}
.listactive1{
  border: 1px solid #fea837;
}
.diyixiang:first-child{
  margin-top: 0 !important;
}

</style>
